import { render, staticRenderFns } from "./tenant-update-discount-ratio.vue?vue&type=template&id=1142d7b4&scoped=true&"
import script from "./tenant-update-discount-ratio.vue?vue&type=script&lang=js&"
export * from "./tenant-update-discount-ratio.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1142d7b4",
  null
  
)

export default component.exports