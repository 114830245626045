<template>
  <div>
    <fieldset style="width:97%">
      <legend>搜索信息</legend>
      <el-row :gutter="20">
        <el-col :span="3">
          <el-input v-model="tenantName" placeholder="公司名称"></el-input>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" @click="search">搜索</el-button>
        </el-col>
      </el-row>
    </fieldset>
    <el-row style="margin-top: 10px;padding-left: 10px">
      <el-table
          :data="table.tenantList"
          :row-style="{height:30+'px'}"
          :cell-style="{padding:5+'px'}"
          border
          height="650"
          max-height="650"
          :default-sort="{prop: 'id', order: 'ascending'}"
          style="width: 99%">
        <el-table-column prop="tenantName" label="公司名称" align="center"></el-table-column>
        <el-table-column prop="tenantCity" label="所在城市" align="center"></el-table-column>
        <el-table-column prop="tenantCorporation" label="公司联系人" align="center"></el-table-column>
        <el-table-column prop="tenantPhone" label="公司联系方式" align="center"></el-table-column>
        <el-table-column prop="styleDiscountRatio" label="折扣比例" align="center">
          <template slot-scope="scope">
            <span>{{calculate(scope.row.styleDiscountRatio,100)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="cashPledge" label="押金余额" align="center"></el-table-column>
        <el-table-column prop="advanceCharge" label="订货款余额" align="center"></el-table-column>
<!--        <el-table-column prop="gradeName" label="产品等级" align="center"></el-table-column>-->
        <el-table-column width="300" align="center" fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button-group>

              <el-button type="success" size="mini" @click="openRecharge(scope.row)">充值</el-button>
              <el-button type="primary" size="mini" @click="openBillDetails(scope.row)">明细</el-button>
              <el-button type="warning" size="mini" @click="openDiscountRatioState(scope.row)">折扣</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 5px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="this.table.limit"
          layout="prev, pager, next,total, jumper, sizes"
          :total="table.total">
      </el-pagination>
    </el-row>
    <tenantRecharge v-if="dialog.rechargeState" :tenant="dialog.tenant" :state="dialog.rechargeState" @close="close"/>
    <tenantBillDetails v-if="dialog.billDetailsState" :tenant="dialog.tenant" :state="dialog.billDetailsState" @close="close"/>
    <tenantUpdateDiscountRatio v-if="dialog.discountRatioState" :tenant="dialog.tenant" :state="dialog.discountRatioState" @close="close"/>
  </div>
</template>

<script>
import tenantRecharge from "@/pages/tenant/tenant-list/tenant-recharge";
import tenantBillDetails from "@/pages/tenant/tenant-list/tenant-bill-details";
import tenantUpdateDiscountRatio from "@/pages/tenant/tenant-list/tenant-update-discount-ratio";
import mathUtils from "@/common/utils/math-utils"
export default {
  name: "customer-list",
  data() {
    return {
      tenantName: "",
      table: {
        tenantList: [],
        page: 1,
        limit: 100,
        total: 0,
      },
      dialog: {
        rechargeState: false,
        billDetailsState:false,
        discountRatioState:false,
        tenant: {},
      },

    }
  },
  components: {
    tenantRecharge,tenantBillDetails,tenantUpdateDiscountRatio,
  },
  created() {
    this.queryTenantList();
  },
  methods: {
    search() {
      this.table.page = 1;
      this.queryTenantList();
    },
    //查询客户列表
    queryTenantList: function () {
      this.$axios({
        method: "GET",
        url: "/tenant/queryCustomerTenantList",
        params: {
          page: this.table.page,
          limit: this.table.limit,
          tenantName: this.tenantName,
        }
      }).then(response => {
        console.log(response        )
        this.table.total = response.data.data.total;
        this.table.tenantList = response.data.data.list;
      })
    },
    //打开充值页面
    openRecharge(data){
      console.log(data)
      this.dialog.rechargeState = true
      this.dialog.tenant = data
    },
    openBillDetails(data){
      this.dialog.billDetailsState= true
      this.dialog.tenant = data
    },
    openDiscountRatioState(data){
      this.dialog.discountRatioState= true
      this.dialog.tenant = data
    },
    //更改每页长度
    handleSizeChange: function (val) {
      this.table.limit = val;
      this.queryTenantList();
    },
    handleCurrentChange(val) {
      this.table.page = val
      this.queryTenantList()
    },
    //计算折扣
    calculate(val,val1){
      return mathUtils.multiply(val,val1)+"%";
    },
    close:function (state){
      this.dialog.rechargeState=state;
      this.dialog.billDetailsState=state;
      this.dialog.discountRatioState=state;
      this.queryTenantList();
    },
  },
}
</script>

<style scoped>

</style>