<template>
  <el-dialog
    title="折扣编辑"
    width="40%"
    :visible.sync="dialogState"
    :modal-append-to-body="false"
    @close="close"
    center>
    <el-form label-width="100px" :model="form" :rules="rules" style="margin-left: 40px" ref="form">

      <el-row>
        <el-col :span="10">
          <el-form-item label="折扣比例" prop="styleDiscountRatio">
            <el-input type="number" v-model="form.styleDiscountRatio"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="tenantRechargeCheck()">提 交</el-button>
        <el-button @click="close">取 消</el-button>
      </span>
  </el-dialog>
</template>

<script>
import mathUtils from "@/common/utils/math-utils"

export default {
  name: "tenant-update-discount-ratio",
  data() {
    let checkDiscountRatio = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('折扣不能为空'));
      }
      setTimeout(() => {
        if (value > 100 || value < 0) {
          callback(new Error('折扣必须在0-100之间'));
        } else if (value % 1 !== 0) {
          callback(new Error('折扣必须是正整数'));
        } else {
          callback();
        }
      }, 300);
    };
    return {
      form: {
        id: this.tenant.id,
        styleDiscountRatio: Number(mathUtils.multiply(this.tenant.styleDiscountRatio, 100)),
      },
      rules: {
        styleDiscountRatio: [
          {validator: checkDiscountRatio, trigger: 'blur'},
        ],
      },
      dialogState: this.state,
    }
  },
  props: {
    tenant: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    state: {
      type: Boolean,
      required: true,
      default: false,
    }
  },
  methods: {
    tenantRechargeCheck: function () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.updateStyleDiscountRatio();
        }
      })
    },
    //添加用户
    updateStyleDiscountRatio: function () {
      let value = "是否更改为:" + this.form.styleDiscountRatio
      this.$confirm(value, '折扣:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method: "POST",
          url: "/tenant/updateStyleDiscountRatio",
          data: {
            id: this.form.id,
            styleDiscountRatio: mathUtils.divide(this.form.styleDiscountRatio, 100),
          }
        }).then(response => {
          if (response.data.code === 200) {
            this.$message.success("更改成功!")
            let self = this;
            setTimeout(function () {
              self.close()
            }, 1500)
          } else {
            this.$message.error(response.data.msg)
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    close: function () {
      this.$emit("close", false);
    }
  }
}
</script>

<style scoped>

</style>